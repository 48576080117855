import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import { useFormContext, FieldValues } from 'react-hook-form'
import { VehicleType } from '@types_def/models/vehicle.types'
import AlbInput from '@components/common/AlbInput'
import { useTranslation } from 'react-i18next'
import { useUserContext } from '@/contexts/UserContext/index.context'
import { extractOrganisationSettings } from '@/utils/constants/organizations'
import { useEffect, useState } from 'react'

type VehicleTypeOptions = {
  name: string
  value: VehicleType
}

type TVehicle = {
  index: number
}

function Vehicle({ index }: TVehicle) {
  const { userState } = useUserContext()
  if (!userState) throw new Error('Missing userState')
  const [choosenModel, setChoosenModel] = useState<string>('01 Core')
  const { settings } = extractOrganisationSettings(userState)

  const carDefaultValues = settings?.defaultValues?.cars
  const { t } = useTranslation()
  const { setValue } = useFormContext<FieldValues>()

  // const { remove: removeVehicle } = useFieldArray({
  //   name: 'vehicles',
  // })

  useEffect(() => {
    if (!carDefaultValues) return

    const { marque, carType, type, model } =
      carDefaultValues.find((car) => car.model === choosenModel) || {}
    setValue(`vehicles.${index}.marque`, marque)
    setValue(`vehicles.${index}.model`, model)
    setValue(`vehicles.${index}.carType`, carType)
    setValue(`vehicles.${index}.type`, type)
  }, [carDefaultValues, setValue, index, choosenModel])

  const vehicleTypeOptions: VehicleTypeOptions[] = [
    { name: t('delivery.form.vehicle.motorization.gas'), value: VehicleType.GAS },
    { name: t('delivery.form.vehicle.motorization.hybrid'), value: VehicleType.HYBRID },
    { name: t('delivery.form.vehicle.motorization.electric'), value: VehicleType.ELECTRIC },
  ]

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={carDefaultValues ? 6 : 12} lg={carDefaultValues ? 3 : 6}>
          <AlbInput
            name={`vehicles.${index}.carType`}
            label={t('delivery.form.vehicle.category.title')}
            type='SelectField'
            options={[
              { name: t('delivery.form.vehicle.category.a'), value: 'A' },
              { name: t('delivery.form.vehicle.category.b'), value: 'B' },
              { name: t('delivery.form.vehicle.category.c'), value: 'C' },
            ]}
          />
        </Grid>

        {carDefaultValues ? (
          <Grid item xs={6} lg={3}>
            <FormControl fullWidth>
              <InputLabel
                id='vehicule-model-select'
                sx={{
                  textTransform: 'capitalize',
                }}
              >
                {t('delivery.form.vehicle.model')}
              </InputLabel>
              <Select
                label={t('delivery.form.vehicle.model')}
                labelId='vehicule-model-select'
                value={choosenModel}
                onChange={(e) => {
                  setChoosenModel(e.target.value)
                }}
              >
                {carDefaultValues.map((car, index) => (
                  <MenuItem key={`${index}${car.model}`} value={car.model}>
                    Model: {car.model}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        ) : null}

        <Grid item xs={12} lg={6}>
          <Grid container>
            <Grid item xs={11}>
              <AlbInput
                name={`vehicles.${index}.type`}
                label={t('delivery.form.vehicle.motorization.title')}
                type='RadioField'
                options={vehicleTypeOptions}
              />
            </Grid>
            <Grid item xs={1}>
              {/* {watch('numberOfVehicles') > 1 && (
                <Tooltip title='Supprimer'>
                  <IconButton
                    size='large'
                    onClick={() => {
                      remove(index)
                      setValue('numberOfVehicles', getValues('numberOfVehicles') - 1)
                    }}
                  >
                    <HighlightOffIcon color='error' />
                  </IconButton>
                </Tooltip>
              )} */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} lg={3}>
          <AlbInput type='TextField' name={`vehicles.${index}.vin`} label='VIN' />
        </Grid>
        <Grid item xs={12} lg={3}>
          <AlbInput
            type='TextField'
            name={`vehicles.${index}.vehicleCode`}
            label={t('delivery.form.vehicle.licence-plate')}
          />
        </Grid>
        <Grid item lg={3} xs={12}>
          <AlbInput
            type='TextField'
            name={`vehicles.${index}.marque`}
            label={t('delivery.form.vehicle.brand')}
          />
        </Grid>
        <Grid item lg={3} xs={12}>
          <AlbInput
            type='TextField'
            name={`vehicles.${index}.model`}
            label={t('delivery.form.vehicle.model')}
          />
        </Grid>
        {/* {watch('numberOfVehicles') > 1 && (
          <Grid item xs={12}>
            <Divider />
          </Grid>
        )} */}
      </Grid>
    </>
  )
}

export default Vehicle
