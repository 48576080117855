import { Car, UserContextData } from '@/types/models/user.type'
import { strToEnum } from '@/utils/strEnum'
import { DeliveryType } from '@types_def/models/delivery.types'

export const COMPANY_SETTINGS = [
  {
    name: 'Lynk & Co',
    id: 'lynkco',
    settings: {
      one_date: true,
      no_docs: true,
      defaultValues: {
        documents: [
          {
            name: 'Handover-client-return-FR',
            key: 'https://lynkco.eu1.echosign.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhDMFEIzGqVEohQcHvfM-qjcAuZdJyiwMrLQdPg5DREqt0mPGrrcxgX2BHHKhm1Xv9g*',
            url: 'https://lynkco.eu1.echosign.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhDMFEIzGqVEohQcHvfM-qjcAuZdJyiwMrLQdPg5DREqt0mPGrrcxgX2BHHKhm1Xv9g*',
            deliveryType: 'TO_RETURN',
          },
          {
            name: 'Handover-standard-FR',
            key: 'https://lynkco.eu1.echosign.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhDHZqoatGK4PTLxggNDkE1BPBuw_M1nFKcIYrr0H8-0_1j-arvb_YsCqw21oDrocJ4*',
            url: 'https://lynkco.eu1.echosign.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhDHZqoatGK4PTLxggNDkE1BPBuw_M1nFKcIYrr0H8-0_1j-arvb_YsCqw21oDrocJ4*',
            deliveryType: 'STANDARD',
          },
          {
            name: 'Handover-okm-FR',
            key: 'https://lynkco.eu1.echosign.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhA-aa09WIftCGwa4vx9geEoEli62eGUS3gX29A36N3_g64rHb9HV0q2-UQAR43Bl5g*',
            url: 'https://lynkco.eu1.echosign.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhA-aa09WIftCGwa4vx9geEoEli62eGUS3gX29A36N3_g64rHb9HV0q2-UQAR43Bl5g*',
            deliveryType: 'OKM',
          },
          {
            name: 'Handover-swap-FR',
            key: 'https://lynkco.eu1.echosign.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhBHL--PrYxgN9_EXP9y8F-MG-GHI2p_zZ712jp007OYINDi3KA5iBFJgs1onqr5Aqo*',
            url: 'https://lynkco.eu1.echosign.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhBHL--PrYxgN9_EXP9y8F-MG-GHI2p_zZ712jp007OYINDi3KA5iBFJgs1onqr5Aqo*',
            deliveryType: 'TWO_WAY_DELIVERY',
          },
          {
            name: 'Handover-client-return-BE',
            key: 'https://lynkco.eu1.echosign.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhADUDG4mCeQvOy7isgY4XBJZkFQXumQB-FTPbuB_7DGJftmut1xCXr9k82U6AiNz-Q*',
            url: 'https://lynkco.eu1.echosign.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhADUDG4mCeQvOy7isgY4XBJZkFQXumQB-FTPbuB_7DGJftmut1xCXr9k82U6AiNz-Q*',
            deliveryType: 'TO_RETURN',
          },
          {
            name: 'Handover-standard-BE',
            key: 'https://lynkco.eu1.echosign.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhDb0LxDRfoZBegLNmua1yhQGgpatzFq7ghPd2E2zoev3Cro-BuN4k2MekYGSO8iuIQ*',
            url: 'https://lynkco.eu1.echosign.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhDb0LxDRfoZBegLNmua1yhQGgpatzFq7ghPd2E2zoev3Cro-BuN4k2MekYGSO8iuIQ*',
            deliveryType: 'STANDARD',
          },
          {
            name: 'Handover-swap-BE',
            key: 'https://lynkco.eu1.echosign.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhATrruUnFMSH-Uz8figB2dgMEYO820FIAE1CoVAxfsrRFMG5OWWMJq6-TQTGDORsHU*',
            url: 'https://lynkco.eu1.echosign.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhATrruUnFMSH-Uz8figB2dgMEYO820FIAE1CoVAxfsrRFMG5OWWMJq6-TQTGDORsHU*',
            deliveryType: 'TWO_WAY_DELIVERY',
          },
        ],
        cars: [
          { type: 'HYBRID', model: '01', marque: 'L&C', carType: 'B' },
          { type: 'ELECTRIC', model: '02', marque: 'L&C', carType: 'B' },
        ],
      },
    },
  },
]
// this function will take delivery type and country and return the correct document
/**
 * Retrieves a document from the given settings based on the delivery type and country.
 * @param deliveryType - The delivery type of the document.
 * @param country - The country code ('FR' or 'BE') used to filter the document by name.
 * @param defaultValues - The LynkCoSettings object containing the default values and documents.
 * @returns The document matching the delivery type and country, or undefined if not found.
 */
export const getDocumentFromSetting = (
  deliveryTypeParam: 'two-way-delivery' | 'standard' | 'to-return' | 'okm',
  country: 'FR' | 'BE',
  defaultValues: LynkCoSettings['defaultValues'],
) => {
  const deliveryType = strToEnum<DeliveryType>(deliveryTypeParam)
  const document = defaultValues.documents.find(
    (doc) => doc.deliveryType === deliveryType && doc.name.includes(country),
  )
  return document
}

export type LynkCoSettings = {
  one_date: boolean
  no_docs: boolean
  defaultValues: {
    documents: {
      name: string
      key: string
      url: string
      deliveryType: DeliveryType
    }[]
    cars: Car[]
  }
}

export const DEFAULT_SETTINGS = {
  one_date: false,
  no_docs: false,
  defaultValues: { documents: undefined, cars: undefined },
}

const mode = import.meta.env.MODE

export const extractOrganisationSettings = (userState: UserContextData) => {
  const isDev = mode === 'development' || mode === 'staging'
  const org = userState.organizationId
  const LynkCoId = isDev ? 'lynko' : 'lynkco'
  const isLynkCo = org === LynkCoId
  const settings = isLynkCo ? (userState.settings as LynkCoSettings) : DEFAULT_SETTINGS
  return { settings, isLynkCo, org }
}
