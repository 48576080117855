import styled from 'styled-components'
import { type Delivery } from '@types_def/models/delivery.types'
import MiddleArrow from './Arrow'
import DeliveryDetailsEndpoint from './Endpoint'
import { useState } from 'react'
import FilesComponent from './FilesComponent'
import { useTranslation } from 'react-i18next'
import HelpIcon from '@mui/icons-material/Help'
import { Tooltip } from '@mui/material'
import PhotoModal from '../../DeliveryFormV2/PhotoModal'

type Props = {
  delivery: Delivery
  index?: number
  isAdmin?: boolean
}
const DeliveryDetailsEndPoints = styled.div`
  position: relative;
  padding: 2rem;
  border-radius: 8px;
  background: #c9ccd5;
  display: flex;
  gap: 2.5rem;
  justify-content: space-between;
`
const EndPointSection = ({ delivery, index = 0, isAdmin = false }: Props) => {
  const { t } = useTranslation()
  const [carImagesBeforeModalOpen, setCarImagesBeforeModalOpen] = useState<boolean>(false)
  const [carImagesAfterModalOpen, setCarImagesAfterModalOpen] = useState<boolean>(false)

  const handleSetCarImagesBeforeModalOpen = (open: boolean) => {
    setCarImagesBeforeModalOpen(open)
  }

  const handleSetCarImagesAfterModalOpen = (open: boolean) => {
    setCarImagesAfterModalOpen(open)
  }
  {
    return (
      <>
        {Boolean(delivery?.vehicles[index]?.imagesAfter) && (
          <FilesComponent
            title={t('delivery.image-of-arrived')}
            files={delivery?.vehicles[index].imagesAfter}
            isOpen={carImagesAfterModalOpen}
            handleModel={handleSetCarImagesAfterModalOpen}
          />
        )}

        {Boolean(delivery?.vehicles[index]?.imagesBefore) && (
          <FilesComponent
            title={t('delivery.image-of-departure')}
            files={delivery?.vehicles[index].imagesBefore}
            isOpen={carImagesBeforeModalOpen}
            handleModel={handleSetCarImagesBeforeModalOpen}
          />
        )}

        <DeliveryDetailsEndPoints>
          <Tooltip
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              cursor: 'pointer',
            }}
            title={
              index === 0
                ? t('delivery.summary.delivered-vehicle-information')
                : t('delivery.summary.restituted-vehicle-information')
            }
            placement='left'
          >
            <HelpIcon color='primary' />
          </Tooltip>

          <DeliveryDetailsEndpoint
            date={index === 0 ? delivery?.departedAt : null}
            km={delivery.vehicles[index]?.mileageBefore}
            fuel={delivery.vehicles[index]?.essenceBefore}
            anomaly={Boolean(delivery?.vehicles[index]?.imagesBefore)}
            handleSetCarImagesModalOpen={handleSetCarImagesBeforeModalOpen}
          />
          <MiddleArrow />
          <DeliveryDetailsEndpoint
            type='login'
            date={index === 0 ? delivery?.completedAt : null}
            fuel={delivery.vehicles[index]?.essenceAfter}
            km={delivery.vehicles[index]?.mileageAfter}
            anomaly={Boolean(delivery?.vehicles[index]?.imagesAfter)}
            handleSetCarImagesModalOpen={handleSetCarImagesAfterModalOpen}
          />
        </DeliveryDetailsEndPoints>
        <PhotoModal delivery={delivery} isAdmin={isAdmin} index={index} />
      </>
    )
  }
}

export default EndPointSection
