import { DamagedCarImages } from '@/types/models/vehicle.types'
import { Box, Button, Divider, Grid, Modal, Typography } from '@mui/material'
import { useState } from 'react'
import carSchema from '@/assets/images/car-schema.jpg'
import { Delivery } from '@/types/models/delivery.types'
import CarImageUpload from './CarImageUpload'
import VisibilityIcon from '@mui/icons-material/Visibility'
import useUpdateCarImages from '../hooks/useUpdateCarImages'
import { t } from 'i18next'
function isEmpty(obj: Record<string, any>) {
  for (const prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false
    }
  }

  return true
}
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  minWidth: '1000px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
}

type Props = {
  delivery: Delivery
  isAdmin: boolean
  index: number
}

const PhotoModal = ({ delivery, isAdmin, index }: Props) => {
  const [open, setOpen] = useState(false)

  const vehicle = delivery.vehicles[index]

  const imageKeyBefore = `Cars/imagesBefore/${delivery.id}-${vehicle.id}-IMAGE_POSITION.FILE_EXTENSION`
  const imageKeyAfter = `Cars/magesAfter/${delivery.id}-${vehicle.id}-IMAGE_POSITION.FILE_EXTENSION`

  const updateCarImages = useUpdateCarImages({ vehicle })

  const onUpload = async (link: string, key: keyof DamagedCarImages) => {
    const before = key.split('/').includes('imagesBefore')
    const images = before ? vehicle.imagesBefore : vehicle.imagesAfter
    const updatedImages: DamagedCarImages = { ...images, [key]: link } as DamagedCarImages
    updateCarImages(updatedImages, before)
  }
  console.log('vehicle', vehicle)
  return (
    <>
      <Button
        disabled={!vehicle?.imagesAfter && !vehicle?.imagesBefore}
        sx={{ marginBlock: 2 }}
        fullWidth
        variant='contained'
        color='primary'
        onClick={() => setOpen(true)}
      >
        {t('common.buttons.view-photos')}
      </Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='car-image-edit-modal-title'
        aria-describedby='car-image-edit-modal-description'
      >
        <Grid container spacing={2} sx={style}>
          <Grid item xs={5}>
            <Typography
              padding={2}
              id='car-image-edit-modal-title-before'
              variant='h6'
              component='h2'
              textAlign={'center'}
              textTransform={'capitalize'}
            >
              {t('delivery.image-of-departure')}
            </Typography>
            <Divider sx={{ marginBottom: 4 }} />
            <Grid
              container
              spacing={2}
              sx={{
                backgroundImage: `url(${carSchema})`,
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                minHeight: '500px',
                minWidth: '400px',
              }}
            >
              <Grid item xs={12}>
                <Box textAlign={'center'}>
                  <CarImageUpload
                    viewOnly={!isAdmin}
                    tooltip='Top Image'
                    imageKey='topImage'
                    url={vehicle?.imagesBefore?.topImage}
                    baseImageKey={imageKeyBefore}
                    onUpload={onUpload}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box textAlign={'center'}>
                  <CarImageUpload
                    viewOnly={!isAdmin}
                    tooltip='Top left Image'
                    imageKey='topLeftImage'
                    url={vehicle?.imagesBefore?.topLeftImage}
                    baseImageKey={imageKeyBefore}
                    onUpload={onUpload}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box textAlign={'center'}>
                  <CarImageUpload
                    viewOnly={!isAdmin}
                    tooltip='Top right Image'
                    imageKey='topRightImage'
                    url={vehicle?.imagesBefore?.topRightImage}
                    baseImageKey={imageKeyBefore}
                    onUpload={onUpload}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box textAlign={'center'}>
                  <CarImageUpload
                    viewOnly={!isAdmin}
                    tooltip='Bottom left Image'
                    imageKey='bottomLeftImage'
                    url={vehicle?.imagesBefore?.bottomLeftImage}
                    baseImageKey={imageKeyBefore}
                    onUpload={onUpload}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box textAlign={'center'}>
                  <CarImageUpload
                    viewOnly={!isAdmin}
                    tooltip='Bottom right Image'
                    imageKey='bottomRightImage'
                    url={vehicle?.imagesBefore?.bottomRightImage}
                    baseImageKey={imageKeyBefore}
                    onUpload={onUpload}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box textAlign={'center'}>
                  <CarImageUpload
                    viewOnly={!isAdmin}
                    tooltip='Bottom Image'
                    imageKey='bottomImage'
                    url={vehicle?.imagesBefore?.bottomImage}
                    baseImageKey={imageKeyBefore}
                    onUpload={onUpload}
                  />
                </Box>
              </Grid>
            </Grid>
            <Divider sx={{ marginTop: 4, marginBottom: 4 }} />
            <Typography
              padding={2}
              id='car-image-edit-modal-title-before'
              variant='h6'
              component='h3'
              textAlign={'left'}
              textTransform={'capitalize'}
            >
              {t('delivery.form.additional-photos')}
            </Typography>
            <Grid container gap={1}>
              {vehicle?.imagesBefore?.extraImages?.length ? (
                vehicle.imagesBefore.extraImages.map((extraImage, index) => {
                  if (!extraImage) return null
                  if (isEmpty(extraImage)) return null

                  const [key, value] = Object.entries(extraImage)[0]
                  return (
                    <Grid item xs={5} key={key}>
                      <Button
                        slot='a'
                        endIcon={<VisibilityIcon />}
                        href={value}
                        target='_blank'
                        rel='noreferrer'
                        size='small'
                        variant='outlined'
                        fullWidth
                        sx={{
                          textAlign: 'left',
                        }}
                      >
                        <span /* className={styles.label} */>{`${index + 1}: ${key} `}</span>
                      </Button>
                    </Grid>
                  )
                })
              ) : (
                <Typography>{t('delivery.form.no-additional-photos')}</Typography>
              )}
            </Grid>
          </Grid>

          <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Divider orientation='vertical' />
          </Grid>

          <Grid item xs={5}>
            <Typography
              padding={2}
              id='car-image-edit-modal-title-after'
              variant='h6'
              component='h2'
              textAlign={'center'}
              textTransform={'capitalize'}
            >
              {t('delivery.image-of-arrived')}
            </Typography>
            <Divider sx={{ marginBottom: 4 }} />
            <Grid
              container
              spacing={2}
              sx={{
                backgroundImage: `url(${carSchema})`,
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                minHeight: '500px',
                minWidth: '400px',
              }}
            >
              <Grid item xs={12}>
                <Box textAlign={'center'}>
                  <CarImageUpload
                    viewOnly={!isAdmin}
                    tooltip='Top Image'
                    imageKey='topImage'
                    url={vehicle?.imagesAfter?.topImage}
                    baseImageKey={imageKeyAfter}
                    onUpload={onUpload}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box textAlign={'center'}>
                  <CarImageUpload
                    viewOnly={!isAdmin}
                    tooltip='Top left Image'
                    imageKey='topLeftImage'
                    url={vehicle?.imagesAfter?.topLeftImage}
                    baseImageKey={imageKeyAfter}
                    onUpload={onUpload}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box textAlign={'center'}>
                  <CarImageUpload
                    viewOnly={!isAdmin}
                    tooltip='Top right Image'
                    imageKey='topRightImage'
                    url={vehicle?.imagesAfter?.topRightImage}
                    baseImageKey={imageKeyAfter}
                    onUpload={onUpload}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box textAlign={'center'}>
                  <CarImageUpload
                    viewOnly={!isAdmin}
                    tooltip='Bottom left Image'
                    imageKey='bottomLeftImage'
                    url={vehicle?.imagesAfter?.bottomLeftImage}
                    baseImageKey={imageKeyAfter}
                    onUpload={onUpload}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box textAlign={'center'}>
                  <CarImageUpload
                    viewOnly={!isAdmin}
                    tooltip='Bottom right Image'
                    imageKey='bottomRightImage'
                    url={vehicle?.imagesAfter?.bottomRightImage}
                    baseImageKey={imageKeyAfter}
                    onUpload={onUpload}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box textAlign={'center'}>
                  <CarImageUpload
                    viewOnly={!isAdmin}
                    tooltip='Bottom Image'
                    imageKey='bottomImage'
                    url={vehicle?.imagesAfter?.bottomImage}
                    baseImageKey={imageKeyAfter}
                    onUpload={onUpload}
                  />
                </Box>
              </Grid>
            </Grid>
            <Divider sx={{ marginTop: 4, marginBottom: 4 }} />
            <Typography
              padding={2}
              id='car-image-edit-modal-title-before'
              variant='h6'
              component='h3'
              textAlign={'left'}
              textTransform={'capitalize'}
            >
              {t('delivery.form.additional-photos')}
            </Typography>
            <Grid container item gap={2}>
              {vehicle?.imagesAfter?.extraImages?.length ? (
                vehicle.imagesAfter.extraImages.map((extraImage, index) => {
                  const [key, value] = Object.entries(extraImage)[0]
                  return (
                    <Grid item xs={5} key={key}>
                      <Button
                        slot='a'
                        endIcon={<VisibilityIcon />}
                        href={value}
                        target='_blank'
                        rel='noreferrer'
                        size='small'
                        sx={{
                          fontSize: '0.8rem',
                        }}
                      >
                        <span /* className={styles.label} */
                        >{`Photo supplémentaire ${key} ${index + 1}`}</span>
                      </Button>
                    </Grid>
                  )
                })
              ) : (
                <Typography>{t('delivery.form.no-additional-photos')}</Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </>
  )
}

export default PhotoModal
