import { useEffect, useState } from 'react'
import { Mission } from './type'
import { useMap } from '@vis.gl/react-google-maps'
import { DeliveryStatus, DeliveryType } from '@types_def/models/delivery.types'
import { Button, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import CustomMarker from './Marker'
import DriverMarker from './DriverMarker'
import { getDeliveryTypeColor } from '@utils/functions/Delivery/DeliveryType/deliveryTypeAttributes'
import { addRoutes } from './utils'
import { useTranslation } from 'react-i18next'
type Props = {
  mission: Mission
  selected: string | null
  setSelectedMission: (id: string | null) => void
}

const MissionMarkers = ({ mission, selected, setSelectedMission }: Props) => {
  const { t } = useTranslation()
  const map = useMap()
  const isSelected = selected === mission.id
  const [routes, setRoutes] = useState<google.maps.DirectionsRenderer[]>([])
  console.log({ isSelected, selected, mission })
  useEffect(() => {
    if (!map) return
    const bounds = new google.maps.LatLngBounds()
    if (isSelected) {
      routes.map((route) => route.setMap(null))
      if (mission.type === DeliveryType.TWO_WAY_DELIVERY && mission.toReturn?.lat) {
        const datas = [
          {
            color: getDeliveryTypeColor(mission.type),
            origin: mission.destination,
            destination: mission.toReturn,
            weight: 2,
            index: 1,
          },
          {
            origin: mission.origin,
            destination: mission.destination,
            index: 2,
          },
        ]
        datas.forEach((data) => {
          const routeInstance = addRoutes(map, data)
          bounds.extend(data.origin)
          bounds.extend(data.destination)
          setRoutes((v) => [...v, routeInstance])
        })
        map.fitBounds(bounds)
      } else if (mission.type === DeliveryType.STANDARD && mission.origin?.lat) {
        const data = {
          origin: mission.origin,
          destination: mission.destination,
          index: 1,
        }
        const routeInstance = addRoutes(map, data)
        setRoutes((v) => [...v, routeInstance])
        bounds.extend(data.origin)
        bounds.extend(data.destination)
        map.fitBounds(bounds)
      }
    } else {
      routes.map((route) => route.setMap(null))
    }
  }, [isSelected, map])

  return (
    <>
      <CustomMarker
        key={mission.id + 'destination-marker'}
        mission={mission}
        title={
          <h5
            className='marker-title'
            style={{
              whiteSpace: 'normal',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <span
              style={{
                textDecoration: `underline ${getDeliveryTypeColor(mission.type)}`,
                textUnderlineOffset: '2px',
                textTransform: 'capitalize',
              }}
            >
              {t('common.shared.delivery')}: {mission.id}
            </span>
            <span
              style={{
                width: 13,
                height: 13,
                background: getDeliveryTypeColor(mission.type),
                display: 'block',
                borderRadius: '50%',
              }}
            ></span>
          </h5>
        }
        description={t('common.shared.arrival-point')}
        position={mission.destination}
        extendable={mission.status === DeliveryStatus.ONGOING}
        extraStyle={{
          opacity: !selected ? 1 : isSelected ? 1 : 0.5,
          bgColor: '#334B49',
        }}
      >
        <motion.div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
            position: 'relative',
            zIndex: 99,
          }}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
          transition={{
            delay: 0.47,
          }}
        >
          <Typography sx={{ color: 'white', fontSize: 12, fontStyle: 'normal' }} variant='body1'>
            {mission.arrive}
          </Typography>
          {selected ? (
            <Button
              variant='contained'
              color='secondary'
              size='small'
              onClick={() => {
                setSelectedMission(null)
              }}
            >
              {t('common.shared.hide-route')}
            </Button>
          ) : (
            <Button
              variant='contained'
              size='small'
              onClick={async () => {
                if (!mission || !mission.origin || !mission.destination || !mission.id) return
                setSelectedMission(mission.id)
              }}
            >
              {t('common.shared.show-route')}
            </Button>
          )}
          {/* <Button
            fullWidth
            variant='outlined'
            color='info'
            size='small'
            endIcon={<LinkOutlined />}
            component={ReactRouter}
            to={`/admin/pilot/two-way-delivery/ongoing/${mission.id}`}
          >
            {t('common.shared.delivery-details')}
          </Button> */}
        </motion.div>
      </CustomMarker>
      {/* origin marker */}
      {isSelected || mission.id === '0' ? (
        <CustomMarker
          extraStyle={{
            bgColor: '#3B3C70',
          }}
          key={mission.id + 'origin-marker'}
          mission={mission}
          title={
            <h5
              className='marker-title'
              style={{
                whiteSpace: 'normal',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <span style={{ textTransform: 'capitalize' }}>
                {t('common.shared.departure-point')}
              </span>
            </h5>
          }
          description={mission.originCity}
          position={mission.origin}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 10,
            }}
          >
            <Typography sx={{ color: 'white', fontSize: 12, fontStyle: 'normal' }} variant='body1'>
              {mission.depart}
            </Typography>
          </div>
        </CustomMarker>
      ) : null}

      {/* restitution */}
      {isSelected && mission.toReturn?.lat ? (
        <CustomMarker
          extraStyle={{
            bgColor: '#28293d',
          }}
          key={mission.id + 'restitution-marker'}
          mission={mission}
          title={
            <h5
              className='marker-title'
              style={{
                whiteSpace: 'normal',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <span style={{ textTransform: 'capitalize' }}>
                {t('common.shared.restitution-point')}
              </span>
            </h5>
          }
          position={mission.toReturn}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 10,
            }}
          >
            <Typography sx={{ color: 'white', fontSize: 12, fontStyle: 'normal' }} variant='body1'>
              {mission.depart}
            </Typography>
          </div>
        </CustomMarker>
      ) : null}
      {/* driver marker */}
      {mission.driver && map ? (
        <DriverMarker
          map={map}
          key={mission.id + '-' + 'driver marker'}
          description={`id : ${mission.driver.id}`}
          title={mission.driver.name}
          driver={mission.driver}
          id={mission.id + ''}
          color={mission.color}
          containerStyles={{
            opacity: !selected ? 1 : isSelected ? 1 : 0.1,
          }}
        />
      ) : null}
    </>
  )
}

export default MissionMarkers
