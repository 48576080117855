import { DamagedCarImages, Vehicle } from "@/types/models/vehicle.types";
import { useUpdateVehicle } from "../Querys";


type useUpdateCarImagesT = {
    vehicle: Vehicle
}
const useUpdateCarImages = ({ vehicle }: useUpdateCarImagesT) => {
    const { mutate: updateVehicle } = useUpdateVehicle();

    const updateCarImages = async (images: DamagedCarImages, before: boolean) => {
        console.log({before})
        const modifiedVehicle = {
            ...vehicle,
            ...(before ? { imagesBefore: images, ...{imagesAfter: vehicle?.imagesAfter ? vehicle?.imagesAfter : undefined} } : { imagesAfter: images, ...{imagesBefore: vehicle?.imagesAfter ? vehicle?.imagesAfter : undefined} }),
        };
        updateVehicle(modifiedVehicle);
    };
  return updateCarImages
}

export default useUpdateCarImages