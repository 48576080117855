import {
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  Stack,
  FormLabel,
  Autocomplete,
} from '@mui/material'
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
  useFormContext,
  useWatch,
} from 'react-hook-form'

import { PhoneMask } from '../InputFormat'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker, DesktopDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { MuiTelInput } from 'mui-tel-input'
import { useTranslation } from 'react-i18next'
import React from 'react'

type TGenericInput = {
  name: string
  label: string
  field: ControllerRenderProps<FieldValues, string>
  type:
    | 'TextField'
    | 'PhoneField'
    | 'DateField'
    | 'DateTimeField'
    | 'SelectField'
    | 'RadioField'
    | 'TextAreaField'
    | 'Autocomplete'
  isEditAllowed?: boolean
  variant?: 'standard' | 'outlined' | 'filled'
  inputType?: 'text' | 'email' | 'password' | 'number'
  disablePast?: boolean
  greaterThan?: string
  options?: { label?: string; name?: string; value: string | number }[]
  error: any
}

const GenericInput = ({
  name,
  field,
  label,
  type,
  isEditAllowed = true,
  variant = 'standard',
  inputType = 'text',
  disablePast = false,
  options,
  error,
  greaterThan,
}: TGenericInput) => {
  const { t } = useTranslation()
  const greaterThanValue = greaterThan ? dayjs(useWatch({ name: greaterThan })) : null
  if (type === 'Autocomplete') {
    return (
      <Autocomplete
        disablePortal
        options={options}
        fullWidth
        disabled={!isEditAllowed}
        onChange={(event, value) => field.onChange(value)}
        value={field.value}
        renderInput={(params) => (
          <TextField
            InputLabelProps={field.value?.length ? { shrink: true } : {}}
            label={label}
            variant={variant}
            helperText={error ? t(`common.form.errors.${error?.message}`) : ''}
            error={Boolean(error)}
            {...field}
            {...params}
          />
        )}
      />
    )
  } else if (['TextField', 'TextAreaField'].includes(type)) {
    return (
      <TextField
        {...field}
        sx={{
          textTransform: 'capitalize',
        }}
        inputRef={field.ref}
        error={Boolean(error)}
        helperText={error ? t(`common.form.errors.${error?.message}`) : ''}
        fullWidth
        variant={variant}
        label={label}
        InputLabelProps={field.value?.length ? { shrink: true } : {}}
        disabled={!isEditAllowed}
        type={inputType}
        multiline={type === 'TextAreaField'}
        InputProps={{
          startAdornment: type === 'PhoneField' ? '(+33) ' : undefined,
          inputComponent: type === 'PhoneField' ? (PhoneMask as any) : undefined,
          style: { minHeight: type === 'TextAreaField' ? '100px' : 'auto' },
        }}
      />
    )
  } else if (type === 'PhoneField') {
    return (
      <MuiTelInput
        {...field}
        inputRef={field.ref}
        sx={{
          textTransform: 'capitalize',
        }}
        label={label}
        error={Boolean(error)}
        defaultCountry='FR'
        disabled={!isEditAllowed}
        variant='standard'
        InputLabelProps={field.value?.length ? { shrink: true } : {}}
        helperText={error ? t(`common.form.errors.${error?.message}`) : ''}
        fullWidth
      />
    )
  } else if (type === 'DateTimeField')
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={dayjs.locale('fr')}>
        <DesktopDateTimePicker
          {...field}
          inputRef={field.ref}
          value={dayjs(field.value) ?? null}
          disablePast={disablePast}
          ampm={false}
          sx={{ width: '100%' }}
          label={label}
          views={['month', 'day', 'year', 'hours', 'minutes']}
          slotProps={{
            textField: {
              error: Boolean(error),
              helperText: error ? t(`common.form.errors.${error?.message}`) : '',
            },
          }}
          minDateTime={greaterThanValue}
          disabled={!isEditAllowed}
        />
      </LocalizationProvider>
    )
  else if (type === 'DateField')
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={dayjs.locale('fr')}>
        <DesktopDatePicker
          inputRef={field.ref}
          {...field}
          disablePast={disablePast}
          sx={{ width: '100%' }}
          label={label}
          views={['month', 'day', 'year']}
          slotProps={{
            textField: {
              error: Boolean(error),
              helperText: error ? t(`common.form.errors.${error?.message}`) : '',
            },
          }}
          minDate={greaterThanValue}
          disabled={!isEditAllowed}
        />
      </LocalizationProvider>
    )
  else if (type === 'SelectField')
    return (
      <FormControl disabled={!isEditAllowed} fullWidth error={Boolean(error)}>
        <InputLabel
          id={name}
          sx={{
            textTransform: 'capitalize',
          }}
        >
          {label}
        </InputLabel>
        <Select {...field} labelId={name} label={label} inputRef={field.ref}>
          {options &&
            options.map((option, index) => (
              <MenuItem key={`${index}${name}`} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
        </Select>
        <FormHelperText>{error ? t(`common.form.errors.${error?.message}`) : ''}</FormHelperText>
      </FormControl>
    )
  else if (type === 'RadioField')
    return (
      <FormControl disabled={!isEditAllowed} error={Boolean(error)} fullWidth>
        <FormLabel id={name} sx={{ textTransform: 'capitalize' }}>
          {label}
        </FormLabel>
        <RadioGroup row {...field} aria-labelledby={name}>
          <Stack direction='row' width={'80%'} justifyContent={'space-between'}>
            {options &&
              options.map((option, idx) => (
                <FormControlLabel
                  sx={{ textTransform: 'capitalize' }}
                  key={`${idx}${name}`}
                  value={option.value}
                  control={<Radio />}
                  label={option.name}
                  onChange={field.onChange}
                />
              ))}
          </Stack>
        </RadioGroup>
        <FormHelperText>{error ? t(`common.form.errors.${error?.message}`) : ''}</FormHelperText>
      </FormControl>
    )
}

type TAlbInput = {
  name: string
  label: string
  type:
    | 'TextField'
    | 'PhoneField'
    | 'DateField'
    | 'DateTimeField'
    | 'SelectField'
    | 'RadioField'
    | 'TextAreaField'
    | 'Autocomplete'
  isEditAllowed?: boolean
  variant?: 'standard' | 'outlined' | 'filled'
  inputType?: 'text' | 'email' | 'password' | 'number'
  disablePast?: boolean
  greaterThan?: string
  options?: { label?: string; name?: string; value: string | number }[]
}
const AlbInput = ({
  name,
  label,
  type,
  isEditAllowed,
  variant,
  inputType = 'text',
  disablePast,
  options,
  greaterThan,
}: TAlbInput) => {
  const { control } = useFormContext()
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <GenericInput
          {...{
            field,
            inputType,
            name,
            label,
            type,
            isEditAllowed,
            variant,
            options,
            disablePast,
            error,
            greaterThan,
          }}
        />
      )}
    />
  )
}

export default AlbInput
