import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera'
import { Button } from '@mui/material'
import { useDropzone } from 'react-dropzone'
import LoadingIcon from '@/components/common/icons/loading.icon'
import axios from 'axios'
import { DamagedCarImages } from '@/types/models/vehicle.types'
import VisibilityIcon from '@mui/icons-material/Visibility'

type Props = {
  tooltip: string
  imageKey: keyof DamagedCarImages
  url?: string
  baseImageKey: string
  onUpload: (link: string, key: keyof DamagedCarImages) => void
  viewOnly?: boolean
}

const CarImageUpload = ({
  tooltip,
  imageKey,
  url,
  baseImageKey,
  onUpload,
  viewOnly = true,
}: Props) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  const onDropRejected = () => {
    toast.info('Only image files are allowed', { position: 'bottom-right' })
  }

  const onDropAccepted = useCallback(
    async (acceptedFiles: File[]) => {
      setLoading(true)
      try {
        const file = acceptedFiles[0]
        const fileName = file.name
        const fileExtension = fileName.slice(fileName.lastIndexOf('.') + 1)

        const formData = new FormData()
        formData.append('file', file)

        const key = baseImageKey
          .replace('IMAGE_POSITION', imageKey.toLowerCase())
          .replace('FILE_EXTENSION', fileExtension)
        formData.append('key', key)

        const response = await axios.post('/s3/upload-files', formData)
        onUpload(response.data.Location, imageKey)
      } catch (error: unknown) {
        console.error(error)
        toast.error(t('network.errors.unknown'), { position: 'bottom-right' })
      } finally {
        setLoading(false)
      }
    },
    [baseImageKey, imageKey, onUpload, t],
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    accept: { 'image/*': ['.jpeg', '.jpg', '.png'] }, // Fixed accept
    onDropRejected,
  })

  return (
    <Button
      {...getRootProps()}
      onClick={() => url && window.open(url, '_blank')}
      title={tooltip}
      sx={{ position: 'relative', width: '48px', height: '60px', borderRadius: '50%' }}
      variant='contained'
      color='primary'
    >
      {loading ? (
        <LoadingIcon />
      ) : viewOnly ? (
        <VisibilityIcon
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      ) : (
        <PhotoCameraIcon
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      )}
      {!viewOnly ? <input {...getInputProps()} /> : null}
    </Button>
  )
}

export default CarImageUpload
